import React, { useContext, useState } from "react";
import { navigate } from "gatsby"
import firebase from "gatsby-plugin-firebase"

import styled from "styled-components";
import { Modal } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";

const ModalStyled = styled(Modal)`
  /* &.modal {
    z-index: 10050;
  } */
`;

const ModalSignIn = (props) => {
  const [showPass, setShowPass] = useState(true);
  const gContext = useContext(GlobalContext);
  const [data, setData] = useState({
    email: "",
    password: "",
  })

  const handleChange = e =>
    setData({ ...data, [e.target.name]: e.target.value })

  const handleSubmit = async e => {
    e.preventDefault()
    try {
      const result = await firebase
        .auth()
        .signInWithEmailAndPassword(data.email, data.password)
      //console.log(result.user)
      setData({ email: "", password: "", })
      if (!result.user.emailVerified) {
        alert("Please verify your email!")
        await firebase.auth().signOut()
        let header = gContext.header
        header.button = "account"
        header.variant = "primary"
        gContext.setHeader(header)
        gContext.toggleSignInModal();
        navigate("/")
      }
      else {
        gContext.setUser(result.user);
        //console.log(gContext.user);
        gContext.toggleSignInModal();
        gContext.setHeader({ button: "profile" });
        if (result.user.email === "info@VOCALGLOCAL.org")
          navigate("/dashboard-admin");
      }
    } catch (err) {
      alert("Incorrect email id and/or password!")
      setData({ email: "", password: "", })
      gContext.toggleSignInModal();
    }
  }

  const handleClose = () => {
    gContext.toggleSignInModal();
  };

  const togglePassword = () => {
    setShowPass(!showPass);
  };

  const handlePasswordReset = () => {
    firebase.auth().sendPasswordResetEmail(data.email)
      .then(() => {
        alert('Password reset email sent to ', data.email)
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log(errorCode, errorMessage);
        alert("Enter a valid email");
      });
  }
  
  return (
    <ModalStyled
      {...props}
      size="lg"
      centered
      show={gContext.signInModalVisible}
      onHide={gContext.toggleSignInModal}
    >
      <Modal.Body className="p-0">
        <button
          type="button"
          className="circle-32 btn-reset bg-white pos-abs-tr mt-md-n6 mr-lg-n6 focus-reset z-index-supper"
          onClick={handleClose}
        >
          <i className="fas fa-times"></i>
        </button>
        <div className="login-modal-main bg-white rounded-8 overflow-hidden">
          <div className="row no-gutters">
            {
            <div className="col-lg-5 col-md-6">
              <div className="pt-10 pb-6 pl-11 pr-12 bg-black-2 h-100 d-flex flex-column dark-mode-texts">
                <div className="pb-9">
                  <h3 className="font-size-8 text-white line-height-reset pb-4 line-height-1p4">
                    Welcome Back
                  </h3>
                  {/*
                  <p className="mb-0 font-size-4 text-white">
                    Log in to continue your account and explore new opportunities.
                  </p>
                  */}
                </div>
              </div>
            </div>
            }
            <div className="col-lg-7 col-md-6">
              <div className="bg-white-2 h-100 px-11 pt-11 pb-7">
                <form id="login-form" onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label
                      htmlFor="email"
                      className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                    >
                      E-mail
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="example@gmail.com"
                      id="email"
                      name="email"
                      value={data.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="password"
                      className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                    >
                      Password
                    </label>
                    <div className="position-relative">
                      <input
                        type={showPass ? "password" : "text"}
                        className="form-control"
                        id="password"
                        placeholder="Enter password"
                        name="password"
                        value={data.password}
                        onChange={handleChange}
                        required
                      />
                      <a
                        href="/#"
                        className="show-password pos-abs-cr fas mr-6 text-black-2"
                        onClick={(e) => {
                          e.preventDefault();
                          togglePassword();
                        }}
                      >
                        <span className="d-none">none</span>
                      </a>
                    </div>
                  </div>
                  <div className="form-group d-flex flex-wrap justify-content-between">
                    {/*
                    <label
                      htmlFor="terms-check"
                      className="gr-check-input d-flex  mr-3"
                    >
                      <input
                        className="d-none"
                        type="checkbox"
                        id="terms-check"
                      />
                      <span className="checkbox mr-5"></span>
                      <span className="font-size-3 mb-0 line-height-reset mb-1 d-block">
                        Remember password
                      </span>
                    </label>
                    */}
                    <a
                      className="font-size-3 text-dodger line-height-reset"
                      style={{ marginLeft: "auto" }}
                      onClick={handlePasswordReset}
                    >
                      Forgot Password
                    </a>
                  </div>
                  <div className="form-group mb-8">
                    <button className="btn btn-primary btn-medium w-100 rounded-5 text-uppercase">
                      Log In{" "}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </ModalStyled>
  );
};

export default ModalSignIn;
