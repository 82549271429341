exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/About.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-affiliate-profile-js": () => import("./../../../src/pages/affiliate-profile.js" /* webpackChunkName: "component---src-pages-affiliate-profile-js" */),
  "component---src-pages-agreement-terms-js": () => import("./../../../src/pages/agreement-terms.js" /* webpackChunkName: "component---src-pages-agreement-terms-js" */),
  "component---src-pages-candidate-profile-js": () => import("./../../../src/pages/candidate-profile.js" /* webpackChunkName: "component---src-pages-candidate-profile-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/Careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-customer-contact-js": () => import("./../../../src/pages/customer-contact.js" /* webpackChunkName: "component---src-pages-customer-contact-js" */),
  "component---src-pages-customer-profile-js": () => import("./../../../src/pages/customer-profile.js" /* webpackChunkName: "component---src-pages-customer-profile-js" */),
  "component---src-pages-dashboard-admin-js": () => import("./../../../src/pages/dashboard-admin.js" /* webpackChunkName: "component---src-pages-dashboard-admin-js" */),
  "component---src-pages-dashboard-applicants-js": () => import("./../../../src/pages/dashboard-applicants.js" /* webpackChunkName: "component---src-pages-dashboard-applicants-js" */),
  "component---src-pages-dashboard-jobs-js": () => import("./../../../src/pages/dashboard-jobs.js" /* webpackChunkName: "component---src-pages-dashboard-jobs-js" */),
  "component---src-pages-dashboard-main-js": () => import("./../../../src/pages/dashboard-main.js" /* webpackChunkName: "component---src-pages-dashboard-main-js" */),
  "component---src-pages-dashboard-settings-js": () => import("./../../../src/pages/dashboard-settings.js" /* webpackChunkName: "component---src-pages-dashboard-settings-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-fstest-js": () => import("./../../../src/pages/fstest.js" /* webpackChunkName: "component---src-pages-fstest-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-job-details-js": () => import("./../../../src/pages/job-details.js" /* webpackChunkName: "component---src-pages-job-details-js" */),
  "component---src-pages-letsdoit-js": () => import("./../../../src/pages/letsdoit.js" /* webpackChunkName: "component---src-pages-letsdoit-js" */),
  "component---src-pages-partner-contact-js": () => import("./../../../src/pages/partner-contact.js" /* webpackChunkName: "component---src-pages-partner-contact-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-templates-provider-js": () => import("./../../../src/templates/provider.js" /* webpackChunkName: "component---src-templates-provider-js" */)
}

