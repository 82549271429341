import React from "react";
import { Link } from "gatsby";

import imgL1Logo from "../../assets/image/logo-main-black.png";
import imgL1LogoWhite from "../../assets/image/logo-main-white.png";

const Logo = ({ white, height, className = "", ...rest }) => {
  return (
    <Link to="/" className={`d-block ${className}`} {...rest}>
      {white ? (
        <>
          <span style={{ fontSize: "24px", color: "white" }}><b>VocalGlocal</b></span><br />
        </>
      ) : (
        <img src={imgL1Logo} alt="" />
      )}
    </Link>
  );
};

{/*
  <div style={{backgroundColor:"#054479", padding:"2px 8px"}}>
    <span style={{fontSize:"28px", color:"white"}}><b>VocalGlocal</b></span><br/>
    <span style={{fontSize:"16px", color:"white"}}><b>Marketplace ~ B.o.W</b></span>
  </div>
*/}

export default Logo;
