export const menuItems = [
  {
    name: "#home",
    label: "Home",
    items: [
      {
        name: "About",
        label: "About Us"
      },
      {
        name: "contact",
        label: "Contact Us"
      }
    ],
  },
  {
    name: "#offerings",
    label: "Offerings",
  },
  {
    name: "#industries",
    label: "Industries",
  },
  {
    name: "#affiliates",
    label: "Partners",
    items: [
      {
        name: "partner-contact",
        label: "Contact Form"
      }
    ],
  }
];
